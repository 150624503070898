import Model from './Model';

export default class Main {
    constructor() {
        this.in = false;
        this.time;
        this.paySelected = true;
        this.crewSelected = false;
        this.mainContainer = document.getElementById('mainContainer');
        this.header = document.createElement('div');
        this.header.classList.add('header');
        this.mainContainer.appendChild(this.header);

        this.headerMain = document.createElement('div');
        this.headerMain.classList.add('headerMain');
        this.header.appendChild(this.headerMain);

        this.logo = document.createElement('div');
        // this.logo.innerHTML = 'Superior Stage';
        this.logo.classList.add('logo');
        this.headerMain.appendChild(this.logo);

        this.confirm = document.createElement('div');

        this.confirm.classList.add('noConfirm');

        let checkCircle = document.createElement('div');
        this.confirm.appendChild(checkCircle);
        let check = document.createElement('div');
        check.classList.add('check');
        checkCircle.appendChild(check);
        this.circle = document.createElement('div');
        this.circle.classList.add('circle');
        checkCircle.appendChild(this.circle);
        this.confQty = document.createElement('div');
        this.confQty.classList.add('confQty');
        this.circle.appendChild(this.confQty);

        let conf = document.createElement('div');
        conf.classList.add('conf');
        conf.innerHTML = 'Confirmations';
        this.confirm.appendChild(conf);


        this.headerMain.appendChild(this.confirm);
        this.confirm.addEventListener("click", this.confirmSelected.bind(this));

        this.headerBack = document.createElement('div');
        this.headerBack.classList.add('no-headerBack');
        this.headerBack.id = "goBack";
        this.headerBack.innerHTML = `<div id="backWrapper"></div><div id="selectedDiv"></div>`;
        this.header.appendChild(this.headerBack);

        let leftDiv = document.getElementById('backWrapper');
        let backIcon = document.createElement('div');
        backIcon.classList.add('back');
        leftDiv.appendChild(backIcon);
        let backText = document.createElement('div');
        backText.innerHTML = 'Back';
        backIcon.classList.add('backText');
        leftDiv.appendChild(backText);

        this.headerDaysFilter = document.createElement('select');
        this.headerDaysFilter.id = 'headerDaysFilter';
        this.headerDaysFilter.classList.add('headerDaysFilter');
        this.days30 = document.createElement('option');
        this.days30.value = '30';
        this.days30.innerHTML = '30 Days';
        this.days60 = document.createElement('option');
        this.days60.value = '60';
        this.days60.innerHTML = '60 Days';
        this.days90 = document.createElement('option');
        this.days90.value = '90';
        this.days90.innerHTML = '90 Days';
        this.headerDaysFilter.appendChild(this.days30);
        this.headerDaysFilter.appendChild(this.days60);
        this.headerDaysFilter.appendChild(this.days90);
        this.headerDaysFilter.addEventListener("change", this.daysFilterChanged.bind(this));


        this.headerConfirm = document.createElement('div');
        this.headerConfirm.innerHTML = `<div id="confirmTitle">Confirmations</div><div class="headerDaysFilterDiv"></div><div id="confirmBtn">Close</div>`;
        const headerDaysFilterDiv = this.headerConfirm.querySelector('.headerDaysFilterDiv');
        headerDaysFilterDiv.appendChild(this.headerDaysFilter);
        this.headerConfirm.classList.add('no-headerConfirm');
        this.header.appendChild(this.headerConfirm);

        this.selectedDiv = document.getElementById('selectedDiv');

        this.enter = document.createElement('div');
        this.enter.innerHTML = `<div class="outer">
        <div class="fieldDiv"><input type="text" id="un" class="field" placeholder="Username"/></div>
        <div class="fieldDiv"><input type="password" id="pass" class="field" placeholder="Password"/></div>
        <div class="goDiv"><div id="done" class="goBtn" >Log In</div></div>
        </div>`;

        this.loadingOverlay = document.createElement('div');
        this.loadingOverlay.innerHTML = `<div  class="loadingText">Loading...</div><div class="loadingAnimation"></div>`;
        this.loadingOverlay.classList.add('notLoading');
        this.mainContainer.appendChild(this.loadingOverlay);

        this.enter.classList.add('enter');
        this.mainContainer.appendChild(this.enter);

        this.nav = document.createElement('div');
        // this.nav.innerHTML = `<div><div id="sched" class="noBtn" >Schedule</div><div id="pay" class="btn" >Payroll</div></div>`;
        this.nav.classList.add('noNav');
        this.mainContainer.appendChild(this.nav);

        this.dataContainer = document.createElement('div');
        this.dataContainer.classList.add('noDataContainer');
        // this.mainContainer.appendChild(this.dataContainer);

        this.scheduleData = document.createElement('div');
        this.scheduleData.classList.add('no-scheduleData');
        this.mainContainer.appendChild(this.scheduleData);

        this.scheduleDetail = document.createElement('div');
        this.scheduleDetail.classList.add('no-scheduleDetail');
        this.mainContainer.appendChild(this.scheduleDetail);

        this.payrollData = document.createElement('div');
        this.payrollData.classList.add('no-payrollData');
        this.mainContainer.appendChild(this.payrollData);

        this.payrollDetail = document.createElement('div');
        this.payrollDetail.classList.add('no-payrollDetail');

        this.detailTitle = document.createElement('div');
        this.detailTitle.classList.add('detailTitle');
        this.scheduleDetail.appendChild(this.detailTitle);

        this.detailTime = document.createElement('div');
        this.detailTime.classList.add('detailItem');
        this.scheduleDetail.appendChild(this.detailTime);

        this.detailAddress = document.createElement('div');
        this.detailAddress.classList.add('detailAddress');
        this.scheduleDetail.appendChild(this.detailAddress);

        this.detailContact = document.createElement('div');
        this.detailContact.classList.add('detailItem');
        this.scheduleDetail.appendChild(this.detailContact);

        this.phone = document.createElement('div');
        this.phone.classList.add('phone');
        this.dateDiv = document.createElement('div');
        this.dateDiv.classList.add('dateDiv');
        this.detailContact.appendChild(this.phone);
        this.detailContact.appendChild(this.dateDiv);

        this.contactName = document.createElement('div');
        this.detailContact.appendChild(this.contactName);
        this.detailContact.addEventListener("click", this.siteContactChosen.bind(this));

        this.detailNotes = document.createElement('div');
        this.detailNotes.classList.add('detailItem');
        this.scheduleDetail.appendChild(this.detailNotes);

        this.detailDress = document.createElement('div');
        this.detailDress.classList.add('detailItem');
        this.scheduleDetail.appendChild(this.detailDress);

        this.detailTools = document.createElement('div');
        this.detailTools.classList.add('detailItem');
        this.scheduleDetail.appendChild(this.detailTools);

        this.detailParking = document.createElement('div');
        this.detailParking.classList.add('detailItem');
        this.scheduleDetail.appendChild(this.detailParking);

        this.detailMeeting = document.createElement('div');
        this.detailMeeting.classList.add('detailItem');
        this.scheduleDetail.appendChild(this.detailMeeting);

        this.detailSheets = document.createElement('div');
        this.detailSheets.classList.add('detailItem');
        this.dnBtn = document.createElement('div');
        this.dnBtn.innerHTML = 'Download Timesheet';
        this.dnBtn.classList.add('timeBtn');
        this.dnBtn.addEventListener("click", this.download.bind(this));
        // this.detailSheets.appendChild(this.dnBtn); decided to remove. Timesheets are now emailed to crew.
        this.upBtn = document.createElement('div');
        this.form = document.createElement('FORM');
        this.form.name = 'myForm';
        this.fileSelector = document.createElement('input');
        this.fileSelector.setAttribute('type', 'file');
        this.fileSelector.name = 'photo';
        this.fileSelector.addEventListener("change", this.fileChanged.bind(this));
        this.formId = document.createElement('input');
        this.formId.setAttribute('type', 'text');
        this.formId.name = 'recid';
        this.formUser = document.createElement('input');
        this.formUser.setAttribute('type', 'text');
        this.formUser.name = 'id';
        this.form.appendChild(this.fileSelector);
        this.form.appendChild(this.formId);
        this.form.appendChild(this.formUser);
        this.upBtn.innerHTML = 'Upload Timesheet';
        this.upBtn.classList.add('timeBtn');
        this.upBtn.addEventListener("click", this.upload.bind(this));
        this.detailSheets.appendChild(this.upBtn);
        this.scheduleDetail.appendChild(this.detailSheets);

        this.detailFiles = document.createElement('div');
        this.detailFiles.classList.add('detailItem');
        this.scheduleDetail.appendChild(this.detailFiles);

        this.mainContainer.appendChild(this.payrollDetail);

        this.confirmData = document.createElement('div');
        this.confirmData.classList.add('no-confirmData');
        this.mainContainer.appendChild(this.confirmData);

        this.crewData = document.createElement('div');
        this.crewData.classList.add('no-crewData');
        this.mainContainer.appendChild(this.crewData);

        this.mainNav = document.createElement('div');
        this.mainNav.innerHTML = `<div><div id="sched" class="noBtn" >Schedule</div><div id="pay" class="btn" >Payroll</div></div>`;
        this.mainNav.classList.add('mainNav');
        this.nav.appendChild(this.mainNav);

        this.scheduleNav = document.createElement('div');
        this.scheduleNav.innerHTML = `<div><div id="detail" class="noBtn" >Details</div><div id="crew" class="btn" >Crew</div></div>`;
        this.scheduleNav.classList.add('no-scheduleNav');
        this.nav.appendChild(this.scheduleNav);

        this.payrollNav = document.createElement('div');
        // this.payrollNav.innerHTML = `<div id="payLeft">Total</div><div id="payCenter">Center</div><div id="payRight">Right</div>`;
        this.payrollNav.classList.add('no-payrollNav');
        this.nav.appendChild(this.payrollNav);

        this.payLeft = document.createElement('div');
        this.payLeft.id = 'payLeft';
        this.payLeft.innerHTML = 'Total';
        this.payCenter = document.createElement('div');
        this.payCenter.id = 'payCenter';
        this.payRight = document.createElement('div');
        this.payRight.id = 'payRight';
        this.payrollNav.appendChild(this.payRight);
        this.payrollNav.appendChild(this.payLeft);
        this.payrollNav.appendChild(this.payCenter);



        let done = document.getElementById('done');
        done.addEventListener("click", this.goLogin.bind(this));

        this.sched = document.getElementById('sched');
        // this.sched.addEventListener("click", this.navSelected.bind(this));

        this.pay = document.getElementById('pay');
        // this.pay.addEventListener("click", this.navSelected.bind(this));

        this.detail = document.getElementById('detail');
        this.detail.addEventListener("click", this.detailSelected.bind(this));

        this.crew = document.getElementById('crew');
        this.crew.addEventListener("click", this.detailSelected.bind(this));

        this.goBack = document.getElementById('goBack');
        this.goBack.addEventListener("click", this.backSelected.bind(this));

        this.confirmBtn = document.getElementById('confirmBtn');
        this.confirmBtn.addEventListener("click", this.confirmBack.bind(this));

        document.onmousemove = this.resetTimer.bind(this);
        document.onkeypress = this.resetTimer.bind(this);
    }

    resetTimer() {
        if (Model.loggedin) {
            clearTimeout(this.time);
            this.time = setTimeout(function () { this.goLogout() }.bind(this), 1000 * 60 * 30);
        }

    }
    out() {
        console.log('out')
    }

    goLogin() {
        this.loading(true);
        let val = "un=" + document.getElementById('un').value + "&ps=" + document.getElementById('pass').value;
        this.loadData('login', val, function (data) {
            this.manageData(data);
        }.bind(this));
    }
    goLogout() {
        console.log('logout');
        this.setState('login');
        let val = "id=" + Model.id;
        Model.loggedin = false;
        this.loading(false);
        this.loadData('logout', val, function (data) {
            this.manageData(data);
        }.bind(this));
    }
    confirmShift(id) {
        let val = "id=" + Model.id + "&recid=" + id;
        this.loadData('confirm', val, function (data) {
            this.updateConfirm(data);
        }.bind(this));
    }
    declineShift(id) {
        let val = "id=" + Model.id + "&recid=" + id;
        this.loadData('decline', val, function (data) {
            this.updateConfirm(data);
        }.bind(this));
    }
    getCrew() {
        let val = "shiftId=" + Model.selectedShift.fieldData.ssp_SSH_recid + "&id=" + Model.id + "&show=" + Model.selectedShift.fieldData.ssp_SHO_zpk;
        // console.log(Model.selectedShift);
        // console.log(val);
        this.loadData('crew', val, function (data) {
            this.manageCrew(data);
        }.bind(this));
    }

    getPayroll() {  // This brings back the payroll periods (2 week blocks)
        let val = "id=" + Model.id + "&payroll=" + new Date();
        this.loadData('payroll', val, function (data) {
            this.managePayroll(data);
        }.bind(this));
    }

    getSchedule() {
        let val = "id=" + Model.id;
        this.loadData('schedule', val, function (data) {
            this.manageSchedule(data);
        }.bind(this));
    }

    loading(toggle) {
        if (toggle) {
            this.loadingOverlay.classList.remove('notLoading');
            this.loadingOverlay.classList.add('loading');
        } else {
            this.loadingOverlay.classList.remove('loading');
            this.loadingOverlay.classList.add('notLoading');
        }
    }

    loadFormData(cmd, val, success) {
        let parent = this;
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.status == 502) {
                console.log('bad');
                this.goLogout();
                parent.setState('login');
            }
            if (this.readyState == 4 && this.status == 200) {
                success(true);

            } else {
                success(false);
            }
        }
        xhttp.open("POST", Model.ipAddress + cmd, true);
        // xhttp.setRequestHeader("Content-type", "multipart/form-data");
        xhttp.send(val);
    }


    loadData(cmd, val, success) {
        // console.log('loadData');
        // console.log(cmd);
        // console.log(val);

        let parent = this;


        // console.log('goSelected');
        let xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function () {
            if (this.status == 502) {
                console.log('bad');
                this.loading(false);
                this.goLogout();
                parent.setState('login');
            }
            if (this.readyState == 4 && this.status == 200) {
                success(xhttp.responseText);
                console.log(this.readyState);
            }
        };
        xhttp.open("POST", Model.ipAddress + cmd, true);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send(val);
    }

    navSelected() {
        // console.log(this.paySelected);
        if (this.paySelected) {
            this.paySelected = false;
            this.getPayroll();
        } else {
            this.paySelected = true;
            this.getSchedule()
        }
    }


    detailSelected() {
        //if(Model.lead) {
        if (this.crewSelected) {
            this.crewSelected = false;
            this.crew.classList.remove('noBtn');
            this.crew.classList.add('btn');
            this.detail.classList.remove('btn');
            this.detail.classList.add('noBtn');
            this.setState('scheduleDetail');
        } else {
            this.crewSelected = true;
            this.crew.classList.remove('btn');
            this.crew.classList.add('noBtn');
            this.detail.classList.remove('noBtn');
            this.detail.classList.add('btn');
            this.getCrew();
        }
        //}
    }

    addressSelected() {

        let addy = Model.selectedShift.fieldData.ssp_SHO_addressLocation + `,` + Model.selectedShift.fieldData.ssp_SHO_addressStreet + `,` + Model.selectedShift.fieldData.ssp_SHO_addressCity + `,` + Model.selectedShift.fieldData.ssp_SHO_addressState;
        let url = 'http://local.google.com/maps?q=Address=' + addy;
        var win = window.open(url, '_blank');
        win.focus();
    }
    fileSelected(e) {
        // console.log(Model.selectedShift);
        Model.selectedShift.portalData.ssp_DOC.forEach(function (doc) {
            if (doc.recordId == e.target.id) {
                var win = window.open(doc.ssp_DOC_document, '_blank');
                win.focus();
            }

        }.bind(this));
    }

    declineChosen(e) {
        console.log(e.target.parentElement.id);
        this.declineShift(e.target.parentElement.id)
    }
    confirmChosen(e) {
        // console.log(e.target.parentElement.id);
        this.confirmShift(e.target.parentElement.id)
    }

    backSelected() {
        if (Model.currentState == 'payrollDetail') {
            this.setState('payroll');
        }
        if (Model.currentState == 'scheduleDetail') {
            this.setState('schedule');
        }
        if (Model.currentState == 'scheduleCrew') {

            this.setState('schedule');
        }
    }
    confirmBack() {

        console.log('confirmBack');
        this.loading(true);
        Model.ignoreConfirm = true;
        // this.setState('schedule');
        this.getSchedule();
    }

    confirmSelected() {
        console.log('confirm selected');
        this.setState('confirmation');
        Model.ignoreConfirm = false;
    }

    upload() {
        this.fileSelector.click();
    }
    testFile(e) {
        let val = new FormData();
        val.append('id', Model.selectedShift.fieldData.ssp_SSH_zpk);
        val.append("photo", e.path[0].files[0]);
        let tt = "id=" + Model.id;
        this.loadData('test', tt, function (data) {
            this.testResponse(data);
        }.bind(this));
    }

    testResponse(data) {

    }

    daysFilterChanged(e) {
        console.log('daysFilterChanged');
        console.log(e.target.value);
        Model.daysFilter = e.target.value;
        // Model.shifts.pop();
        Model.ignoreConfirm = false;
        this.buildSchedule();
        this.setState('confirmation');
    }

    fileChanged(e) {
        console.log(e);
        this.formId.value = Model.selectedShift.fieldData.ssp_SSH_zpk;
        this.formUser.value = Model.id;
        console.log(this.formUser.value);
        // this.fileSelector.value = e.path[0].files[0];
        let val = new FormData(this.form);

        // val.append('id', Model.selectedShift.fieldData.ssp_SSH_zpk);
        // val.append("photo", e.path[0].files[0]);
        this.loadFormData('upload', val, function (data) {
            this.manageUpload(data);
        }.bind(this));
    }
    manageUpload(data) {
        if (data) {
            alert(' Upload Successful');
            //this.buildSchedule();
        }
    }
    download() {
        console.log('ts', Model.selectedShift.fieldData.ssp_SSH_timesheetPDF);
        var win = window.open(Model.selectedShift.fieldData.ssp_SSH_timesheetPDF, '_blank');
        win.focus();
    }

    managePayroll(data) {
        Model.payrolls = [];
        while (this.payrollData.firstChild) {
            this.payrollData.removeChild(this.payrollData.firstChild);
        }
        let str = data.split('::').join('_');
        let parsed = JSON.parse(str);
        console.log(parsed);
        if (parsed.result) {
            parsed.result.forEach(function (period) {
                Model.payrolls.push(period);
                let div = document.createElement('div');
                div.id = period.recordId;
                this.selectedDiv.innerHTML = period.fieldData.dateEnd + ' Period';
                let startDate = this.toDate(period.fieldData.dateBegin).getTime();
                let endDate = this.toDate(period.fieldData.dateEnd).getTime();
                let totalHours = 0;
                let totalAmount = 0;
                Model.shifts.forEach(function (data) {
                    let shiftDate = this.toDate(data.fieldData.ssp_SSH_dateStart).getTime();
                    if ((shiftDate >= startDate) && (shiftDate < endDate)) {
                        console.log(data.recordId);
                        totalHours += Number(data.fieldData.timeHoursTimesheet);
                        totalAmount += Number(data.fieldData.totalTimesheet);

                    }
                }.bind(this));

                if (totalHours > 0) {
                    div.addEventListener("click", this.payrollChosen.bind(this));
                    let arrow = document.createElement('div');
                    arrow.classList.add('arrow');
                    let payDiv = document.createElement('div');
                    payDiv.classList.add('payHours');
                    payDiv.innerHTML = `<div class="payAddy">` + totalHours + ` Hours</div><div class="payAddy">$` + Number(totalAmount).toFixed(2) + `</div>`;
                    div.appendChild(arrow);
                    div.appendChild(payDiv);
                } else {
                    let empty = document.createElement('div');
                    empty.classList.add('emptyPayroll');
                    empty.innerHTML = '* no entries';
                    div.appendChild(empty);
                }

                let payContent = document.createElement('div');
                payContent.classList.add('payContent');
                payContent.innerHTML = `<div class="payDate">Ending ` + period.fieldData.dateEnd + `</div>`;
                div.classList.add('payItem');
                div.appendChild(payContent);
                this.payrollData.appendChild(div);
            }.bind(this));
        }
        this.setState('payroll');
    }
    payrollChosen(e) {
        while (this.payrollDetail.firstChild) {
            this.payrollDetail.removeChild(this.payrollDetail.firstChild);
        }
        Model.selectedID = e.target.id;
        console.log(Model.selectedID);
        console.log(e.target);

        // this.selectedDiv.innerHTML = shift.fieldData.ssp_SHO_showName;
        Model.payrolls.forEach(function (period) {
            if (period.recordId.toString() == Model.selectedID) {
                this.selectedDiv.innerHTML = period.fieldData.dateEnd + ' Period';
                let startDate = this.toDate(period.fieldData.dateBegin).getTime();
                let endDate = this.toDate(period.fieldData.dateEnd).getTime();
                let totalHours = 0;
                let totalAmount = 0;

                Model.shifts.forEach(function (data) {
                    let shiftDate = this.toDate(data.fieldData.ssp_SSH_dateStart).getTime();
                    if ((shiftDate >= startDate) && (shiftDate < endDate)) {
                        console.log(data.recordId);
                        totalHours += Number(data.fieldData.timeHoursTimesheet);
                        totalAmount += Number(data.fieldData.totalTimesheet);
                        let div = document.createElement('div');
                        div.id = data.recordId;
                        div.classList.add('payItem');
                        let payDiv = document.createElement('div');
                        payDiv.classList.add('payHours');
                        payDiv.innerHTML = `<div class="payAddy">` + data.fieldData.timeHoursTimesheet + ` Hours</div><div class="payAddy">$` + Number(data.fieldData.totalTimesheet).toFixed(2) + `</div>`;
                        div.appendChild(payDiv);
                        let payContent = document.createElement('div');
                        payContent.innerHTML = `<div id=` + data.fieldData.zpk + ` class="payShow">` + data.fieldData.ssp_SHO_showName + `</div><div class="payAddy">` + data.fieldData.ssp_SSH_dateStart + `</div>`;
                        div.classList.add('payItem');
                        div.appendChild(payContent);

                        this.payrollDetail.appendChild(div);

                    }
                    this.payCenter.innerHTML = totalHours + ' Hours';
                    this.payRight.innerHTML = '$' + Number(totalAmount).toFixed(2);

                }.bind(this));
            }
        }.bind(this));
        this.setState('payrollDetail');
    }
    crewChosen(e) {
        Model.selectedID = e.target.id;
        Model.crew.forEach(function (member) {
            if (member.recordId.toString() == Model.selectedID) {
                let phoneNumber = member.fieldData.ssp_PEO_phone.replace(/\D/g, '');
                window.location.href = 'tel:+1' + phoneNumber;
            }
        }.bind(this));
    }

    siteContactChosen(e) {
        Model.shifts.forEach(function (member) {
            if (member.recordId.toString() == Model.selectedID) {
                let phoneNumber = member.portalData.ssp_spe_PEO_onsite[0].ssp_spe_PEO_onsite_phone.replace(/\D/g, '');
                console.log(phoneNumber);
                window.location.href = 'tel:+1' + phoneNumber;
            }
        }.bind(this));
    }

    updateConfirm(data) {
        if (Model.confirmQty > 0) {
            Model.confirmQty--;
        }
        this.getSchedule();
    }

    manageCrew(data) {
        while (this.crewData.firstChild) {
            this.crewData.removeChild(this.crewData.firstChild);
        }
        let str = data.split('::').join('_');
        let parsed = JSON.parse(str);
        console.log('manageCrew', parsed);
        if (parsed.result) {
            parsed.result.forEach(function (crew) {
                Model.crew.push(crew);
                let div = document.createElement('div');
                div.id = crew.recordId;
                div.classList.add('gridDiv');

                let payContent = document.createElement('div');
                //payContent.classList.add('payContent');
                payContent.innerHTML = `<div class="payShow">` + crew.fieldData.ssp_PEO_nameDisplay + `</div><div class="payAddy">` + crew.fieldData.ssp_ROL_role + `</div>`;
                div.addEventListener("click", this.crewChosen.bind(this));
                div.appendChild(payContent);

                let photo = document.createElement('div');
                photo.innerHTML = `<img src="data:image/jpeg;base64, ` + crew.fieldData.ssp_PEO_photoB64 + `"/>`
                div.appendChild(photo);

                let dateDiv = document.createElement('div');
                dateDiv.classList.add('phoneNumber');
                dateDiv.innerHTML = crew.fieldData.ssp_PEO_phone;
                let phone = document.createElement('div');
                phone.classList.add('phone');

                if (crew.fieldData.ssp_PEO_phone != '') {
                    div.appendChild(dateDiv);
                    div.appendChild(phone);
                }
                this.crewData.appendChild(div);
            }.bind(this));
        }
        this.setState('scheduleCrew');
    }

    manageData(data) {
        this.in = true;
        let parsed = JSON.parse(data);
        if (parsed.id == 'nae') {
            Model.loggedin = false; // this is for logout timer
            this.loading(false);
            document.getElementById('un').value = '';
            document.getElementById('pass').value = '';
            document.getElementById('done').innerHTML = 'Please try again';
        } else {
            Model.loggedin = true; // this is for logout timer
            Model.id = parsed.id;
            this.getSchedule();
        }


        //this.manageSchedule(data);


    }

    manageSchedule(data) {
        Model.shifts = [];
        let str = data.split('::').join('_');
        let parsed = JSON.parse(str);
        console.log(parsed);
        if (parsed.result) {
            parsed.result.forEach(function (shift) {
                Model.shifts.push(shift);
            }.bind(this));
        }

        // console.log('Model.shifts confirmed', Model.shifts.filter(shift => shift.fieldData.status.toLowerCase() == 'confirmed'));
        // console.log('Model.shifts awaiting confirmation', Model.shifts.filter(shift => shift.fieldData.status.toLowerCase() == 'awaiting confirmation'));
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(23);
        yesterday.setMinutes(59);
        yesterday.setSeconds(59);
        yesterday.setMilliseconds(0);
        // console.log('Model.shifts', Model.shifts.filter(shift => new Date(shift.fieldData.ssp_SSH_dateStart).getTime() > yesterday.getTime())); //yesterday
        this.buildSchedule();

    }

    buildSchedule() {
        let daysFilter = document.getElementById('headerDaysFilter');

        console.log('daysFilter.value')
        console.log(daysFilter.value)


        console.log('buildSchedule')

        console.log('this.scheduleData', this.scheduleData)

        let confirmDaysForward = new Date();
        confirmDaysForward.setDate(confirmDaysForward.getDate() + parseInt(daysFilter.value));
        confirmDaysForward.setHours(0);
        confirmDaysForward.setMinutes(0);
        confirmDaysForward.setSeconds(0);
        confirmDaysForward.setMilliseconds(0);

        console.log('confirmDaysForward', confirmDaysForward)


        let threeMonthsAgo = new Date();
        threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 30);
        threeMonthsAgo.setHours(0);
        threeMonthsAgo.setMinutes(0);
        threeMonthsAgo.setSeconds(0);
        threeMonthsAgo.setMilliseconds(0);

        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(23);
        yesterday.setMinutes(59);
        yesterday.setSeconds(59);
        yesterday.setMilliseconds(0);

        Model.confirmQty = 0;
        let confirmed = true;
        while (this.scheduleData.firstChild) {
            this.scheduleData.removeChild(this.scheduleData.firstChild);
        }
        while (this.confirmData.firstChild) {
            this.confirmData.removeChild(this.confirmData.firstChild);
        }
        this.createDivider('thisWeek', 'This Week');
        this.createDivider('thisMonth', 'This Month');
        this.createDivider('nextMonth', 'Next Month');
        this.createDivider('beyondNextMonth', 'Beyond Next Month');
        Model.shifts.forEach(function (shift) {
            if (shift.fieldData.ssp_ROL_role == 'Lead') {
                Model.lead = true;
            }
            if (shift.fieldData.status.toLowerCase() == 'awaiting confirmation') {
                let dateStart = shift.fieldData.ssp_SSH_dateStart
                // console.log('dateStart', dateStart)
                if (new Date(dateStart).getTime() < confirmDaysForward.getTime() && new Date(dateStart).getTime() > yesterday.getTime()) { //for days forward
                    this.createConfirmLi(shift);
                    Model.confirmQty++;
                }
                confirmed = false;
            } else if (shift.fieldData.status.toLowerCase() == 'confirmed') {
                console.log(shift.fieldData.ssp_SSH_dateStart);
                if (this.toDate(shift.fieldData.ssp_SSH_dateStart).getTime() > yesterday.getTime()) {
                    console.log('> yesterday', shift.fieldData)
                    this.createSchedLi(shift);

                } else if (this.toDate(shift.fieldData.ssp_SSH_dateStart).getTime() > threeMonthsAgo.getTime()) {
                    // console.log('shift.fieldData.ssp_SSH_status', shift.fieldData.ssp_SSH_status)
                    if ((shift.fieldData.ssp_SSH_status != 'Timesheet Entered') && (shift.fieldData.ssp_SSH_status != 'Timesheet Uploaded')) {
                        console.log('no timesheet', shift.fieldData)
                        if (Model.selectedShift) {
                            if (Model.selectedShift.fieldData.ssp_SSH_zpk != shift.fieldData.ssp_SSH_zpk) {
                                // console.log('shift.fieldData.ssp_ROL_role', shift.fieldData.ssp_ROL_role)
                                if ((shift.fieldData.ssp_ROL_role == 'Crew Chief') || (shift.fieldData.ssp_ROL_role == 'Lead')) {
                                    // this.createSchedLi(shift);
                                }
                            }
                        } else {
                            // console.log('shift.fieldData.ssp_ROL_role', shift.fieldData.ssp_ROL_role)
                            if ((shift.fieldData.ssp_ROL_role == 'Crew Chief') || (shift.fieldData.ssp_ROL_role == 'Lead')) {
                                // this.createSchedLi(shift);
                            }
                        }
                    }
                } else {
                    //console.log('no nothing', shift.fieldData)
                }
            }
        }.bind(this));
        //-------------Added 20190731 to hide crew btn if user is not a lead . Then removed 20200107
        //if(!Model.lead){
        //var element = document.getElementById('crew');
        //if(element != null) {
        //element.parentNode.removeChild(element);
        //}
        //}
        if (Model.confirmQty > 0) {
            // console.log('Model.confirmQty', Model.confirmQty)
            this.confQty.innerHTML = Model.confirmQty.toString();
            this.circle.style.display = 'block';
        } else {
            this.circle.style.display = 'none';
        }
        this.scheduleData.classList.remove('no-scheduleData');
        this.scheduleData.classList.add('scheduleData');
        if ((confirmed) || (Model.ignoreConfirm)) {
            this.setState('schedule');
        } else {
            this.setState('confirmation');
        }
        this.loading(false);
    }
    createDivider(id, name) {
        let header = document.createElement('div');
        header.classList.add('dividerHeader');
        header.innerHTML = name;
        let div = document.createElement('div');
        div.id = id;
        div.classList.add('divider');
        div.appendChild(header);
        let empty = document.createElement('div');
        empty.id = id + 'empty';
        empty.innerHTML = '* no shifts scheduled';
        empty.classList.add('empty');
        div.appendChild(empty);
        this.scheduleData.appendChild(div);
    }

    createConfirmLi(data) {

        // console.log('createConfirmLi', data);
        let div = document.createElement('div');
        div.id = data.recordId;
        div.classList.add('payItem');
        let decline = document.createElement('div');
        decline.classList.add('decline');
        decline.addEventListener("click", this.declineChosen.bind(this));
        let confirmCheck = document.createElement('div');
        confirmCheck.classList.add('confirmCheck');
        confirmCheck.addEventListener("click", this.confirmChosen.bind(this));
        div.appendChild(decline);
        div.appendChild(confirmCheck);
        let payContent = document.createElement('div');
        let addy = data.fieldData.ssp_SHO_addressCity + ' ' + data.fieldData.ssp_SHO_addressState + ' - ' + data.fieldData.ssp_SSH_dateStart;
        payContent.innerHTML = `<div id=` + data.fieldData.zpk + ` class="payShow">` + data.fieldData.ssp_SHO_showName + `</div><div class="payAddy">` + addy + `</div>` +
            `<div class="payAddy">` + data.fieldData.ssp_SSH_timeDisplay + `</div>`
        div.classList.add('payItem');
        div.appendChild(payContent);

        this.confirmData.appendChild(div);
    }

    createSchedLi(data) {
        // console.log('createSchedLi', data);
        let div = document.createElement('div');
        let arrow = document.createElement('div');
        arrow.classList.add('arrow');
        let dateDiv = document.createElement('div');
        dateDiv.classList.add('dateDiv');

        let today = new Date();
        today.setDate(today.getDate());
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);
        tomorrow.setHours(0);
        tomorrow.setMinutes(0);
        tomorrow.setSeconds(0);
        tomorrow.setMilliseconds(0);

        let dayAfter = new Date();
        dayAfter.setDate(new Date().getDate() + 2);
        dayAfter.setHours(0);
        dayAfter.setMinutes(0);
        dayAfter.setSeconds(0);
        dayAfter.setMilliseconds(0);

        if (this.toDate(data.fieldData.ssp_SSH_dateStart).getTime() < today.getTime()) {
            console.log('before', this.toDate(data.fieldData.ssp_SSH_dateStart));
            dateDiv.innerHTML = 'Needs Timesheet';
        } else
            if (this.toDate(data.fieldData.ssp_SSH_dateStart).getTime() < tomorrow.getTime()) {
                //console.log('today', this.toDate(data.fieldData.ssp_SSH_dateStart));
                dateDiv.innerHTML = 'Today';
            } else if (this.toDate(data.fieldData.ssp_SSH_dateStart).getTime() < dayAfter.getTime()) {
                // console.log('tom');
                dateDiv.innerHTML = 'Tomorrow';
            } else {
                dateDiv.innerHTML = data.fieldData.ssp_SSH_dateStart;
            }

        div.appendChild(arrow);
        div.appendChild(dateDiv);

        // let addy = data.fieldData.ssp_SHO_addressStreet+' '+data.fieldData.ssp_SHO_addressCity+' '+data.fieldData.ssp_SHO_addressState;
        let addy = data.fieldData.ssp_SHO_addressLocation; let payContent = document.createElement('div');
        payContent.classList.add('payContent');
        payContent.innerHTML = `<div id = ` + data.fieldData.zpk + ` class="payShow">` + data.fieldData.ssp_SHO_showName + `</div> <div class="payAddy">` + addy + `</div>`;
        div.classList.add('payItem');
        div.appendChild(payContent);

        // let monday = new Date();
        // monday.setDate(monday.getDate() + (1 + 7 - monday.getDay()) % 7);
        let monday = new Date(today);

        if (today.getDay() !== 1) {
            // If today is not Monday, find the next Monday
            monday.setDate(today.getDate() + (1 + 7 - today.getDay()) % 7);
        } else {
            // If today is Monday, get Monday of the following week
            monday.setDate(today.getDate() + 7);
        }

        // console.log(monday);


        monday.setHours(0);
        monday.setMinutes(0);
        monday.setSeconds(0);
        monday.setMilliseconds(0);
        console.log('monday', monday);
        div.id = data.recordId;
        div.addEventListener("click", this.itemChosen.bind(this));
        // console.log(div);
        let nextMonth = this.addMonths(1, new Date());
        let monthAfter = this.addMonths(2, new Date());

        if (this.toDate(data.fieldData.ssp_SSH_dateStart).getTime() < monday.getTime()) {
            let divider = document.getElementById('thisWeek');

            let empty = document.getElementById("thisWeekempty");
            if (empty) {
                empty.remove();
            }
            divider.appendChild(div);
        } else if (this.toDate(data.fieldData.ssp_SSH_dateStart).getTime() < nextMonth.getTime()) {
            let divider = document.getElementById('thisMonth');

            let empty = document.getElementById("thisMonthempty");
            if (empty) {
                empty.remove();
            }
            divider.appendChild(div);
        } else if (this.toDate(data.fieldData.ssp_SSH_dateStart).getTime() < monthAfter.getTime()) {
            let divider = document.getElementById('nextMonth');
            let empty = document.getElementById("nextMonthempty");
            if (empty) {
                empty.remove();
            }
            divider.appendChild(div);
        }
        else {
            let divider = document.getElementById('beyondNextMonth');
            let empty = document.getElementById("beyondNextMonthempty");
            if (empty) {
                empty.remove();
            }
            divider.appendChild(div);
        }
    }

    itemChosen(e) {

        Model.selectedID = e.target.id;
        // console.log(e);
        Model.shifts.forEach(function (shift) {
            // console.log(shift.recordId);
            if (shift.recordId.toString() == Model.selectedID) {
                Model.selectedShift = shift;
                console.log(Model.selectedShift);
                let headerText = shift.fieldData.ssp_SHO_showName;
                if (headerText.length > 20) {
                    headerText = headerText.substring(0, 17) + '...';
                }
                // this.selectedDiv.innerHTML = headerText;
                this.detailTitle.innerHTML = 'SHOW: ' + shift.fieldData.ssp_SHO_showName + '<br>CLIENT: ' + shift.fieldData.ssp_sho_PEO_client_nameDisplay;

                this.detailTime.innerHTML = shift.fieldData.ssp_SSH_dateTimeDisplay;

                this.detailAddress.innerHTML = `<div id = "worldWrapper" ></div> <div class="addyWrapper">` + shift.fieldData.ssp_SHO_addressLocation + `<br>` + shift.fieldData.ssp_SHO_addressStreet + `<br>` + shift.fieldData.ssp_SHO_addressCity + `, ` + shift.fieldData.ssp_SHO_addressState + `</div>`;
                let leftDiv = document.getElementById('worldWrapper');
                let worldIcon = document.createElement('div');
                worldIcon.classList.add('world');
                leftDiv.appendChild(worldIcon);
                this.detailAddress.addEventListener("click", this.addressSelected.bind(this));

                if ((shift.fieldData.ssp_ROL_role == 'Crew Chief') || (shift.fieldData.ssp_ROL_role == 'Lead')) {

                    if (shift.portalData.ssp_spe_PEO_onsite.length > 0) {

                        this.dateDiv.innerHTML = shift.portalData.ssp_spe_PEO_onsite[0].ssp_spe_PEO_onsite_phone;
                        if (shift.portalData.ssp_spe_PEO_onsite[0].ssp_spe_PEO_onsite_nameDisplay != '') {
                            this.phone.style.display = 'block';
                            this.dateDiv.style.display = 'block';
                        } else {
                            this.phone.style.display = 'none';
                            this.dateDiv.style.display = 'none';
                        }
                        this.contactName.innerHTML = `ON SITE CONTACT:<br>` + shift.portalData.ssp_spe_PEO_onsite[0].ssp_spe_PEO_onsite_nameDisplay;
                        this.detailContact.style.display = 'block';
                    }
                } else {
                    this.detailContact.style.display = 'none';
                }

                if (shift.fieldData.ssp_SHO_notes == '') {
                    this.detailNotes.innerHTML = `NOTES<br><div class="emptyDetail">* no notes</div>`;
                } else {
                    this.detailNotes.innerHTML = `NOTES<br>` + shift.fieldData.ssp_SHO_notes;
                }

                if (shift.fieldData.ssp_SHO_dressCode == '') {
                    this.detailDress.innerHTML = `DRESSCODE<br><div class="emptyDetail">* not specified</div>`;
                } else {
                    this.detailDress.innerHTML = `DRESSCODE<br>` + shift.fieldData.ssp_SHO_dressCode;
                }

                if (shift.fieldData.ssp_SHO_tools == '') {
                    this.detailTools.innerHTML = `TOOLS/MATERIALS<br><div class="emptyDetail">* not specified</div>`;
                } else {
                    this.detailTools.innerHTML = `TOOLS/MATERIALS<br>` + shift.fieldData.ssp_SHO_tools;
                }

                if (shift.fieldData.ssp_SHO_parkingInfo == '') {
                    this.detailParking.innerHTML = `PARKING<br><div class="emptyDetail">* not specified</div>`;
                } else {
                    this.detailParking.innerHTML = `PARKING<br>` + shift.fieldData.ssp_SHO_parkingInfo;
                }

                if (shift.fieldData.ssp_SHO_meetingPlace == '') {
                    this.detailMeeting.innerHTML = `MEETING PLACE<br><div class="emptyDetail">* not specified</div>`;
                } else {
                    this.detailMeeting.innerHTML = `MEETING PLACE<br>` + shift.fieldData.ssp_SHO_meetingPlace;
                }

                if ((shift.fieldData.ssp_ROL_role == 'Crew Chief') || (shift.fieldData.ssp_ROL_role == 'Lead')) {
                    this.detailSheets.style.display = 'block';
                } else {
                    this.detailSheets.style.display = 'none';
                }

                if (shift.portalData.ssp_DOC.length > 0) {
                    this.detailFiles.innerHTML = `FILES<br>`;
                    shift.portalData.ssp_DOC.forEach(function (rec) {

                        let doc = document.createElement('div');
                        doc.id = rec.recordId;
                        doc.classList.add('docDiv');
                        doc.innerHTML = rec.ssp_DOC_filename;

                        let pdf = document.createElement('div');
                        pdf.classList.add('pdf');
                        doc.appendChild(pdf);
                        doc.addEventListener("click", this.fileSelected.bind(this));
                        this.detailFiles.appendChild(doc);
                    }.bind(this));
                } else {
                    this.detailFiles.innerHTML = `FILES<br><div class="emptyDetail">* no files</div>`;
                }


            }
        }.bind(this));
        this.setState('scheduleDetail');
    }

    setState(state) {
        Model.currentState = state;
        switch (state) {

            case 'login':
                console.log('set state login')
                this.confirm.classList.remove('confirm');
                this.confirm.classList.add('noConfirm');
                this.enter.classList.remove('noEnter');
                this.enter.classList.add('enter');
                this.nav.classList.remove('nav');
                this.nav.classList.add('noNav');
                this.payrollNav.classList.remove('payrollNav');
                this.payrollNav.classList.add('no-payrollNav');
                this.scheduleNav.classList.remove('scheduleNav');
                this.scheduleNav.classList.add('no-scheduleNav');
                this.payrollData.classList.remove('payrollData');
                this.payrollData.classList.add('no-payrollData');
                this.scheduleNav.classList.remove('scheduleNav');
                this.scheduleNav.classList.add('no-scheduleNav');
                this.scheduleData.classList.add('no-scheduleData');
                this.scheduleData.classList.remove('scheduleData');
                this.scheduleDetail.classList.remove('scheduleDetail');
                this.scheduleDetail.classList.add('no-scheduleDetail');
                this.crewData.classList.remove('crewData');
                this.crewData.classList.add('no-crewData');
                this.payrollDetail.classList.add('no-payrollDetail');
                this.payrollDetail.classList.remove('payrollDetail');
                this.headerBack.classList.remove('headerBack');
                this.headerBack.classList.add('no-headerBack');
                this.headerMain.classList.remove('no-headerMain');
                this.headerMain.classList.add('headerMain');
                document.getElementById('un').value = '';
                document.getElementById('pass').value = '';
                break;
            case 'main':
                console.log('set state main')
                this.confirm.classList.remove('noConfirm');
                this.confirm.classList.add('confirm');
                this.enter.classList.remove('enter');
                this.enter.classList.add('noEnter');
                this.nav.classList.remove('noNav');
                this.nav.classList.add('nav');
                this.payrollNav.classList.remove('payrollNav');
                this.payrollNav.classList.add('no-payrollNav');
                this.scheduleNav.classList.remove('scheduleNav');
                this.scheduleNav.classList.add('no-scheduleNav');
                this.payrollData.classList.remove('payrollData');
                this.payrollData.classList.add('no-payrollData');

                break;
            case 'payroll':
                this.sched.classList.remove('noBtn');
                this.sched.classList.add('btn');
                this.pay.classList.remove('btn');
                this.pay.classList.add('noBtn');
                this.nav.classList.remove('noNav');
                this.nav.classList.add('nav');
                this.mainNav.classList.remove('no-mainNav');
                this.mainNav.classList.add('mainNav');
                this.scheduleNav.classList.remove('scheduleNav');
                this.scheduleNav.classList.add('no-scheduleNav');
                this.headerMain.classList.remove('no-headerMain');
                this.headerMain.classList.add('headerMain');
                this.headerBack.classList.remove('headerBack');
                this.headerBack.classList.add('no-headerBack');
                this.scheduleData.classList.add('no-scheduleData');
                this.scheduleData.classList.remove('scheduleData');
                this.payrollData.classList.add('payrollData');
                this.payrollData.classList.remove('no-payrollData');
                this.payrollDetail.classList.remove('payrollDetail');
                this.payrollDetail.classList.add('no-payrollDetail');
                this.headerConfirm.classList.add('no-headerConfirm');
                this.headerConfirm.classList.remove('headerConfirm');
                this.payrollNav.classList.remove('payrollNav');
                this.payrollNav.classList.add('no-payrollNav');
                break;
            case 'payrollDetail':
                this.enter.classList.remove('enter');
                this.enter.classList.add('noEnter');
                this.mainNav.classList.remove('mainNav');
                this.mainNav.classList.add('no-mainNav');
                this.scheduleNav.classList.remove('scheduleNav');
                this.scheduleNav.classList.add('no-scheduleNav');
                this.payrollNav.classList.remove('no-payrollNav');
                this.payrollNav.classList.add('payrollNav');
                this.headerMain.classList.remove('headerMain');
                this.headerMain.classList.add('no-headerMain');
                this.headerBack.classList.remove('no-headerBack');
                this.headerBack.classList.add('headerBack');
                this.payrollData.classList.remove('payrollData');
                this.payrollData.classList.add('no-payrollData');
                this.payrollDetail.classList.add('payrollDetail');
                this.payrollDetail.classList.remove('no-payrollDetail');
                break;

            case 'schedule':
                this.sched.classList.remove('btn');
                this.sched.classList.add('noBtn');
                this.pay.classList.remove('noBtn');
                this.pay.classList.add('btn');
                this.nav.classList.remove('noNav');
                this.nav.classList.add('nav');
                this.mainNav.classList.remove('no-mainNav');
                this.mainNav.classList.add('mainNav');
                this.scheduleNav.classList.remove('scheduleNav');
                this.scheduleNav.classList.add('no-scheduleNav');
                this.headerMain.classList.remove('no-headerMain');
                this.headerMain.classList.add('headerMain');
                this.headerBack.classList.remove('headerBack');
                this.headerBack.classList.add('no-headerBack');
                this.scheduleData.classList.add('scheduleData');
                this.scheduleData.classList.remove('no-scheduleData');
                this.payrollData.classList.remove('payrollData');
                this.payrollData.classList.add('no-payrollData');
                this.scheduleDetail.classList.remove('scheduleDetail');
                this.scheduleDetail.classList.add('no-scheduleDetail');
                this.headerConfirm.classList.add('no-headerConfirm');
                this.headerConfirm.classList.remove('headerConfirm');
                this.confirmData.classList.remove('confirmData');
                this.confirmData.classList.add('no-confirmData');
                this.confirm.classList.remove('noConfirm');
                this.confirm.classList.add('confirm');
                this.crewData.classList.remove('crewData');
                this.crewData.classList.add('no-crewData');
                this.payrollNav.classList.remove('payrollNav');
                this.payrollNav.classList.add('no-payrollNav');

                this.crewSelected = false;
                this.crew.classList.remove('noBtn');
                this.crew.classList.add('btn');
                this.detail.classList.remove('btn');
                this.detail.classList.add('noBtn');
                break;
            case 'scheduleDetail':

                this.mainNav.classList.remove('mainNav');
                this.mainNav.classList.add('no-mainNav');
                this.scheduleNav.classList.remove('no-scheduleNav');
                this.scheduleNav.classList.add('scheduleNav');
                this.headerMain.classList.remove('headerMain');
                this.headerMain.classList.add('no-headerMain');
                this.headerBack.classList.remove('no-headerBack');
                this.headerBack.classList.add('headerBack');
                this.scheduleData.classList.remove('scheduleData');
                this.scheduleData.classList.add('no-scheduleData');
                this.scheduleDetail.classList.add('scheduleDetail');
                this.scheduleDetail.classList.remove('no-scheduleDetail');
                this.payrollDetail.classList.add('no-payrollDetail');
                this.payrollDetail.classList.remove('payrollDetail');
                this.crewData.classList.add('no-crewData');
                this.crewData.classList.remove('crewData');
                this.enter.classList.remove('enter');
                this.enter.classList.add('noEnter');
                this.payrollNav.classList.remove('payrollNav');
                this.payrollNav.classList.add('no-payrollNav');
                this.scheduleDetail.scrollTop = 0;
                break;
            case 'scheduleCrew':

                this.mainNav.classList.remove('mainNav');
                this.mainNav.classList.add('no-mainNav');
                this.scheduleNav.classList.remove('no-scheduleNav');
                this.scheduleNav.classList.add('scheduleNav');
                this.headerMain.classList.remove('headerMain');
                this.headerMain.classList.add('no-headerMain');
                this.headerBack.classList.remove('no-headerBack');
                this.headerBack.classList.add('headerBack');
                this.payrollData.classList.remove('payrollData');
                this.payrollData.classList.add('no-payrollData');
                this.payrollDetail.classList.remove('payrollDetail');
                this.payrollDetail.classList.add('no-payrollDetail');
                this.scheduleData.classList.remove('scheduleData');
                this.scheduleData.classList.add('no-scheduleData');
                this.scheduleDetail.classList.remove('scheduleDetail');
                this.scheduleDetail.classList.add('no-scheduleDetail');
                this.payrollNav.classList.remove('payrollNav');
                this.payrollNav.classList.add('no-payrollNav');
                this.crewData.classList.remove('no-crewData');
                this.crewData.classList.add('crewData');
                break;
            case 'confirmation':
                console.log('confirmation')
                this.enter.classList.remove('enter');
                this.enter.classList.add('noEnter');
                this.nav.classList.remove('nav');
                this.nav.classList.add('no-nav');
                this.payrollData.classList.remove('payrollData');
                this.payrollData.classList.add('no-payrollData');
                this.payrollDetail.classList.remove('payrollDetail');
                this.payrollDetail.classList.add('no-payrollDetail');

                this.scheduleData.classList.remove('scheduleData');
                this.scheduleData.classList.add('no-scheduleData');
                this.scheduleDetail.classList.remove('scheduleDetail');
                this.scheduleDetail.classList.add('no-scheduleDetail');

                this.mainNav.classList.remove('mainNav');
                this.mainNav.classList.add('no-mainNav');
                this.headerConfirm.classList.remove('no-headerConfirm');
                this.headerConfirm.classList.add('headerConfirm');
                this.headerMain.classList.remove('headerMain');
                this.headerMain.classList.add('no-headerMain');
                this.payrollNav.classList.remove('payrollNav');
                this.payrollNav.classList.add('no-payrollNav');

                this.confirmData.classList.remove('no-confirmData');
                this.confirmData.classList.add('confirmData');
                break;
            default:
            // code block
        }

    }
    addMonths(after = 1, now = new Date()) {
        var current;
        if (now.getMonth() == 11) {
            current = new Date(now.getFullYear() + 1, 0, 1);
        } else {
            current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        }
        return (after == 1) ? current : this.addMonths(after - 1, new Date(now.getFullYear(), now.getMonth() + 1, 1))
    }

    toDate(dateStr) {
        // console.log(dateStr);
        var parts = dateStr.split("/");
        return new Date(parts[2], parts[0] - 1, parts[1])
    }
}
