class Model {
    constructor(){
this.loggedin = false;
        this.id;
        this.shifts = [];
        this.crew = [];
        this.payrolls = [];
        this.currentState;
        this.selectedID;
        this.selectedShift;
        this.ignoreConfirm = false;
        this.confirmQty = 0;
	this.lead = false;
        //this.ipAddress = 'http://fms.superiorstagecrew.com:8080/';
	this.ipAddress = 'https://app.superiorstagecrew.com:8443/';
    }

}
export default (new Model);

