import logMessage from './js/logger'
import './css/style.css'
import Main from './js/main';

// logMessage('Its finished!!');

window.onload = () => {
     let main = new Main();
};



if (module.hot)       // eslint-disable-line no-undef
  module.hot.accept() // eslint-disable-line no-undef